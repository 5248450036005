.contentWrapper{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contentWrapper img{
  width: 30% !important;
}
a{
  text-decoration: none;
}
.contentWrapper p{
  width: 30% !important;
}
@media screen and (max-width: 990px) {
  .contentWrapper{
    flex-direction: column;
  }
  .contentWrapper img{
    width: 60% !important;
    margin-bottom: 70px;
  }
  .contentWrapper p{
    width: 100% !important;
  }
}